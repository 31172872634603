// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-i-18-n-redirect-jsx": () => import("/opt/build/repo/src/i18n/redirect.jsx" /* webpackChunkName: "component---src-i-18-n-redirect-jsx" */),
  "component---src-pages-dev-404-page-js": () => import("/opt/build/repo/src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

